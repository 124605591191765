import Vue from 'vue'
import {
  BootstrapVue, ToastPlugin, ModalPlugin, VBTogglePlugin
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import FormValidatorPlugin from '@/plugins/form-validator-plugin'
import HttpPlugin from '@/plugins/http-plugin'
import BaseModalPlugin from '@/plugins/modal-plugin'

import globalMixin from '@/mixins/globalMixin'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'

// import i18n from '@/libs/i18n'
import '@/libs/flatpickr'
import '@core/scss/vue/libs/quill.scss'
import '@core/scss/vue/libs/vue-select.scss'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'he-tree-vue/dist/he-tree-vue.css'

// Interceptor para agregar el token a las llamadas al API
import '@/api/interceptors/JwtToken'

// Complements
import './libs/veevalidate'

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BaseModalPlugin)
Vue.use(VBTogglePlugin)
Vue.use(FormValidatorPlugin)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(HttpPlugin)

Vue.mixin(globalMixin)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('numberToLocalString', value => {
  if (!value || Number.isNaN(value)) {
    return '0,00'
  }

  const decimalSeparator = ',';
  const decimalDigits = value % 1 !== 0 ? value.toFixed(2).split('.')[1] : '00';
  const integerPart = Math.trunc(value).toLocaleString('de-DE');

  const formattedNumber = `${integerPart}${decimalSeparator}${decimalDigits}`;

  return formattedNumber;
})

Vue.filter('formatDate', value => {
  if (!value) {
    return ''
  }

  return new Date(value).toLocaleDateString()
})

Vue.directive('access', {
  inserted: (el, binding) => {
    const { resource, resourceAction } = binding.value
    if (resource === 'profile') {
      return
    }

    if (!store.getters['auth/canAccessResource'](resource, resourceAction)) {
      // eslint-disable-next-line no-param-reassign
      el.style.display = 'none'
    }
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

if (window.Cypress) {
  window.app = App
  window.app.$store = store
}


